<template>
    <div class="wrapper">    
        <div class="toggle slide-in-right" v-if="!UserQuick" @click="UserQuick =!UserQuick">
            <span class="material-symbols-rounded">Person</span>
            <div class="user-flag" v-if="user"></div>
        </div>

        <div class="user-quick slide-out-right" id="user-quick">
            <div class="header">
                <div class="header-txt">
                    <span class="material-symbols-rounded" style="margin-left:5px;">Person</span>
                    <p>פאנל משתמש</p>
                </div>
                <div class="toggle-icon" @click="UserQuick =!UserQuick">
                    <span class="material-symbols-rounded">arrow_forward_ios</span>
                </div>
            </div>
            <div class="no-user" v-if="!user && !register">
                <div class="model-header">
                    <p>התחברות למערכת</p>
                </div>
                <div class="input-label" v-if="!sending_code">
                    <span class="material-symbols-rounded">phone_iphone</span>
                    <p>מספר פלאפון</p>
                </div>
                <div class="input-field" v-if="!sending_code">
                    <input v-model="phone_number" type="number" name="phone" id="phone" class="txt-input" max="9999999999" @change="checkPhone">
                </div>
                <div class="recaptcha-container" id="recaptcha-container"/>
                <button class="success-btn" @click="send_code" v-if="!sending_code">
                    <span>שלח קוד אימות</span>
                </button>
                <div class="sending" v-if="sending_code">
                    <p>שולח קוד, אנא המתן עד 60 שניות.</p>
                </div>
                <div class="input-field" v-if="sending_code">
                    <input v-model="login_code" type="text" name="phone" id="code" class="txt-input" placeholder="קוד אימות" style="text-align:center;">
                </div>
                <button class="success-btn" @click="login" v-if="sending_code">
                    <span>התחבר</span>
                </button>
                <div class="new-code" v-if="sending_code" @click="reset_login">
                    <p>שלח קוד מחדש</p>
                </div>
            </div>
            <div class="user" v-if="user">
                <div class="placeholder-noorg" v-if="!user.organization">
                    <img src="@/assets/PlaceHolders/new_user.png" alt="">
                    <p style="font-size: 18px; margin-bottom:10px;">משתמש ללא ארגון</p>
                    <p style="color:var(--gold)">אנא הצטרף / הקם ארגון</p>
                </div>
            </div>
            <div class="register" v-if="!user && register">

            </div>
        </div>
    </div>

</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core';
import store from '../../store';
import {projectAuth, projectAuthVer} from '../../Firebase/config';
import {slide_pop_error} from '../../Scripts/Msgs';
export default {
setup(){
    const UserQuick = ref(false);

    const user = ref(computed(() => {
        return store.state.user
    }))

    const register = ref(false);
    const pending_code = ref(false);
    const login_code = ref(null);
    const valid_captha = ref(false);
    const sending_code = ref(false);

    const phone_number = ref('');

    const checkPhone = () => {
        if(String(phone_number.value).length > 11){
            phone_number.value = '';
        }
    }

    const set_captcha = () => {
        if(store.state.user){
            return
        }
        window.recaptchaVerifier = new projectAuthVer.RecaptchaVerifier('recaptcha-container', {size: 'invisible',
            'callback': (response) => {
                valid_captha.value = true;
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                valid_captha.value = false;
            }
        })
        recaptchaVerifier.render()
    }

    const toggle_input = (name) => {
        let el = document.getElementById(name);
        if(name == 'code'){
            login_code.value = null;
        }
        el.focus();
    }

    const send_code = async () => {
        if(!phone_number.value || String(phone_number.value).length != 9){
            slide_pop_error("מספר הפלאפון איננו תקני.");
            toggle_input("phone");
            return
        }
        sending_code.value = true;
        setTimeout(() => {
            toggle_input("code");
        }, 500);
        const appVerifier = window.recaptchaVerifier;
        var p_n = '+972' + phone_number.value
        projectAuth.signInWithPhoneNumber(p_n, appVerifier).then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            pending_code.value = true;
        }).catch(function(error) {
            console.log(error);
        });
    }

    watch(UserQuick, () => {
        if(!UserQuick.value){
            let el = document.getElementById("user-quick");
            if(el){
                el.classList.replace("slide-in-right", "slide-out-right")
            }
        }else{
            let el = document.getElementById("user-quick");
            if(el){
                el.classList.replace("slide-out-right", "slide-in-right")
                el.style.display = 'flex';
                set_captcha();
            }
        }
    })
    
    const login = async () => {
        if(!login_code.value || login_code.value.length != 6){
            slide_pop_error("קוד האימות איננו תקני.");
            toggle_input("code");
            return
        }
        confirmationResult.confirm(login_code.value).then(async(result) => {
        // User signed in successfully.
            const user = result.user;
            console.log(user);
            store.state.user = user;
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log(error);
        });
        
    }

    const reset_login = () => {
        window.recaptchaVerifier.render();
        sending_code.value = false;
    }
    
    return{
        UserQuick, user, register, phone_number, checkPhone, pending_code, send_code,
        sending_code, login_code, login, reset_login,
    }
}
}
</script>

<style scoped>
.wrapper{
    width: 100%;
    max-width: 400px;
    height: 100%;
    direction: rtl;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
}
.user-quick{
    width: calc(100% - 10px);
    max-width: 400px;
    height: calc(100vh - 10px);
    overflow: hidden;
    overflow-y: auto;
    background: var(--grad_bg);
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 100;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
    display: none;
    flex-direction: column;
    direction: rtl;
}
.header{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.toggle{
    width: 60px;
    height: 60px;
    background: var(--light_green);
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 100;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
}
.toggle span{
    font-size: 46px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.273);
}
.header-txt{
    width: calc(100% - 50px);
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    color: var(--light_green);
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.445);
}
.toggle-icon{
    width: 50px;
    height: 100%;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light_green);
    cursor: pointer;
}
.no-user{
    width: 100%;
    height: calc(100% - 40px);
    padding: 5px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.sending{
    width: 100%;
    height: 30px;
    font-size: 18px;
    color: var(--gold);
    text-align: center;
}
.new-code{
    width: 100%;
    text-align: start;
    font-size: 16px;
}
.new-code p{
    cursor: pointer;
    color: var(--danger);
}

.user-flag{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--success);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.344);
    position: fixed;
    top: 5px;
    right: 3px;
    z-index: 101;
}

.user{
    width: 100%;
    height: 100%;
}
.placeholder-noorg{
    width: 100%;
    height: 100%;
    padding: 10px;
    background: var(--dark_btn);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    color: white;
}
.placeholder-noorg p {
    font-weight: 300;
}
.placeholder-noorg img{
    max-width: 100%;
    max-height: 130px;
    margin-bottom: 25px;
}
</style>